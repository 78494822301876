import axios from 'axios';
import env from "./AppEnv";
import { handleHttpError } from "./Alerts";
export function createHttpClient(baseUrl) {
    const client = axios.create({
        baseURL: baseUrl === undefined ? (env.path + '/api/') : baseUrl,
    });
    // defaults to x-www-form-urlencoded
    client.defaults.headers.patch['Content-Type'] = 'application/json';
    client.defaults.headers.post['Content-Type'] = 'application/json';
    client.defaults.headers.put['Content-Type'] = 'application/json';
    client.interceptors.request.use((config) => {
        if (env.security.csrf) {
            // add CSRF header to each request
            config.headers[env.security.csrf.header] = env.security.csrf.token;
        }
        config.headers['x-paloma-route'] = axios.getUri(config);
        return config;
    });
    return client;
}
const http = createHttpClient();
http.interceptors.response.use(res => res, error => {
    // HTTP Unauthorized and no anonymous access enabled: reload
    if (error.response && error.response.status === 401
        && !env.security.anonymousAccessEnabled) {
        reloadIfUnauthenticated();
    }
    // HTTP Forbidden and anonymous access enabled: reload only if authenticated
    if (error.response && error.response.status === 403
        && env.user.authenticated
        && env.security.statusCheck) {
        reloadIfUnauthenticated();
    }
    throw error;
});
function reloadIfUnauthenticated() {
    if (!env.user.authenticated) {
        window.location.reload();
    }
    if (!env.security.statusCheck) {
        return;
    }
    // we might have been logged out (e.g. session expired)
    checkAuthenticationStatus()
        .catch(() => window.location.reload());
}
/**
 * Resolves the promise if authenticated, rejects it otherwise
 */
function checkAuthenticationStatus() {
    if (!env.security.statusCheck) {
        throw 'Unable to check authentication status: env.security.statusCheck not set';
    }
    const client = createHttpClient();
    client.interceptors.request.use((config) => {
        config.headers['x-paloma-route'] = axios.getUri(config);
        return config;
    });
    // create extra instance w/o default interceptors
    return client.get(env.security.statusCheck)
        .then(() => {
    })
        .catch(error => {
        if (error.response && error.response.status === 401) {
            throw 'unauthenticated';
        }
    });
}
class PalomaBackend {
    execute(action, payload) {
        return this
            .post('actions/execute', payload, { action: action });
    }
    get(path, params) {
        return http
            .get(path, { params: params })
            .then((response) => response.data)
            .catch(handleHttpError);
    }
    post(path, payload, params) {
        return http
            .post(path, payload, { params: params, })
            .then((response) => {
            return response.data;
        })
            .catch((error) => {
            if (error.response && error.response.data) {
                throw error.response.data;
            }
            throw error.response;
        });
    }
    delete(path, params) {
        return http
            .delete(path, { params: params })
            .then((response) => response.data)
            .catch(handleHttpError);
    }
    checkAuthenticationStatus() {
        return checkAuthenticationStatus();
    }
    http = http;
}
export default new PalomaBackend();
